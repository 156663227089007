import React, { useState } from 'react';

export default function HeroSection() {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const contactListId = 2;
    const apiKey = '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';
    
    try {
      const response = await fetch('https://api.sendinblue.com/v3/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify({
          email: email,
          listIds: [contactListId],
          updateEnabled: true,
        }),
      });

      if (response.ok) {
        setFeedback('Muito obrigado! Aguarde conteúdos inovadores que transformarão sua forma de gerir.');
        setIsSubmitted(true);
        setEmail('');
      } else {
        const errorData = await response.json();
        setFeedback(`Erro ao adicionar contato: ${errorData.message || response.statusText}`);
      }
    } catch (error) {
      console.error('Erro:', error);
      setFeedback('Ocorreu um erro. Tente novamente mais tarde.');
    }
  };

  return (
    <section className="bg-white dark:bg-neutral-950 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-7 lg:gap-x-12 lg:items-center">
          {/* Texto à esquerda */}
          <div className="lg:col-span-3">
            <h1 className="text-4xl sm:text-3xl lg:text-5xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              Estratégias e Insights para Líderes
            </h1>
            <p className="mt-6 text-lg text-gray-600 dark:text-neutral-400">
              Receba emails com insights práticos, estratégias validadas e materiais exclusivos para empresários e executivos que buscam aprimorar sua gestão com mais controle, clareza e rentabilidade.
            </p>

            {!isSubmitted ? (
              <form onSubmit={handleSubmit} className="mt-8 lg:mt-10 flex flex-col sm:flex-row gap-4 sm:items-center">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Digite seu email corporativo"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="py-3 px-4 w-full sm:w-auto min-w-[20rem] border border-gray-300 rounded-lg text-sm focus:border-blue-600 focus:ring-1 focus:ring-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100 dark:placeholder-neutral-500"
                />
                <button
                  type="submit"
                  className="inline-flex justify-center items-center py-3 px-6 text-sm font-semibold rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition"
                >
                  Quero receber os conteúdos
                </button>
              </form>
            ) : (
              <div className="mt-8">
                <p className="text-lg text-green-600 dark:text-neutral-400">
                  {feedback}
                </p>
              </div>
            )}

            {/* Em caso de erro, mantém a mensagem abaixo do formulário */}
            {!isSubmitted && feedback && (
              <p className="mt-3 text-sm text-gray-500 dark:text-neutral-500">
                {feedback}
              </p>
            )}
          </div>

          {/* Imagem à direita */}
          <div className="lg:col-span-4 mt-12 lg:mt-0">
            <img
              src="/assets/img/about-img.jpeg"
              alt="Gestão Empresarial"
              className="w-full h-auto rounded-2xl shadow-2xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
