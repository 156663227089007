import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import FormPage from './pages/FormPage';
import Footer from './components/Footer';
import Header from './components/Header';
import Quali1 from './pages/Quali1';
import Quali2 from './pages/Quali2';
import NaoQuali from './pages/NaoQuali';
import CallConfirmada from './pages/CallConfirmada';
import LPPage from './pages/LP';
import LPPage2 from './pages/LP-2';
import LPPage3 from './pages/LP-3';
import WhatsAppButton from './components/WhatsAppButton';
import EmailCapture from './pages/EmailCapture'

function Layout({ children }) {
  const location = useLocation();
  const isFormPage = location.pathname === '/form';

  return (
    <div className="min-h-screen flex flex-col justify-between">
      {!isFormPage && <Header />}
      <div className="flex-grow pt-16">
        {children}
      </div>
      {!isFormPage && <Footer />}
      <WhatsAppButton />
    </div>
  );
}

function App() {
  useEffect(() => {
    // Adicionando o Google Tag Manager
    const googleScript = document.createElement('script');
    googleScript.async = true;
    googleScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-2K02KJ7TZ8';
    document.head.appendChild(googleScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-2K02KJ7TZ8');

    // Adicionando Google Ads Pixel
    const googleAdsScript = document.createElement('script');
    googleAdsScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-489116256');
    `;
    document.head.appendChild(googleAdsScript);

    // Adicionando Microsoft Clarity
    (function(c, l, a, r, i, t, y) {
      c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments); };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "o4qb1aosmb");

    // Adicionando Meta Pixel com função regular
    (function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // Inicializando o Meta Pixel
    window.fbq('init', '422934550430287');
    window.fbq('track', 'PageView');
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/performance-operacional" element={<LPPage />} />
          <Route path="/newsletter-vbmc" element={<EmailCapture />} />
          <Route path="/performance" element={<LPPage3 />} />
          <Route path="/sucessao-familiar" element={<LPPage2 />} />
          <Route path="/form" element={<FormPage />} />
          <Route path="/quali-1" element={<Quali1 />} />
          <Route path="/quali-2" element={<Quali2 />} />
          <Route path="/nao-10" element={<NaoQuali />} />
          <Route path="/call-confirmada" element={<CallConfirmada />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
