import React, { useEffect, useState } from 'react';
import Swiper from 'swiper'; // Importa o Swiper básico
import 'swiper/css'; // Importa o CSS correto
import { Navigation } from 'swiper/modules'; // Importa o módulo Navigation
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import ScrollReveal from 'scrollreveal';
import {
  AiFillAppstore,
  AiOutlineDollarCircle,
  AiFillGift,
  AiOutlineLayout,
  AiOutlineCluster,
  AiOutlineTeam
} from 'react-icons/ai'
import { FaUsers, FaPhone, FaClock, FaLinkedin, FaHandshake, FaMedal, FaChartLine, FaCogs, FaShieldAlt, FaLightbulb, FaTools, FaLifeRing } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

Swiper.use([Navigation]); // Ativa o módulo de navegação no Swiper

const LPPage3 = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Coleta os dados do formulário
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    try {
      // Envia os dados para o webhook
      const response = await fetch("https://hook.us2.make.com/rmcectf0rcwdi90u27fety3oxnldll34", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      
      if (response.ok) {
        // Navega para /quali-2 após o envio bem-sucedido
        navigate('/quali-2');
      } else {
        console.error("Erro ao enviar dados:", response.statusText);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    const formSection = document.querySelector('#formSection');
    if (formSection) {
      formSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedItem(null);
  };

  const portfolioItems = [
    {
      id: 1,
      category: 'B2C',
      title: 'Lourenço Castanho',
      image: './assets/img/portfolio/castanho.png',
      description: 'Conheça como a Escola Lourenço Castanho transformou seu modelo de gestão para fortalecer a imagem, fidelizar alunos e alcançar resultados econômicos sólidos com a ajuda da VBMC!',
      details: {
        client: 'Lourenço Castanho é uma conceituada escola localizada na zona oeste de São Paulo, que oferece ensino da Educação Infantil ao Ensino Médio. A Escola sempre foi marcada por um projeto pedagógico inovador, que extrapola o trabalho com os conteúdos e investe também no desenvolvimento da autonomia, da crítica e da dimensão social.',
        challenge: 'Paralelamente ao processo de crescimento e de ampliação do projeto pedagógico de vanguarda, a Lourenço Castanho decidiu investir em seu modelo de Gestão Escolar, desde o processo de captação e fidelização de alunos, passando pela gestão econômico-financeira até a governança corporativa e sucessão.',
        solution: 'Após um intenso diagnóstico empresarial realizado, os sócios da VBMC Consultores apresentaram pela primeira vez às sócias fundadoras da escola um projeto de consultoria que possibilitaria a Lourenço Castanho atingir um novo patamar em seu modelo de Gestão Escolar. Um projeto abrangente de reestruturação do modelo de gestão da escola com a estruturação da área de relacionamento e comunicação, revisão dos processos de captação de novos alunos, revisão do processo de fidelização de alunos e implantação de indicadores de desempenho diversos e da gestão orçamentária por unidade visando a melhoria do resultado econômico. Também atuamos na avaliação e revisão da estrutura organizacional visando preparar a estrutura da escola para a sucessão das sócias na gestão das unidades.',
        results: 'Ao longo de 2004 a 2007, foi desenvolvido e implantado um modelo de gestão de negócio eficiente, focado no fortalecimento da imagem do colégio, estruturação de uma área de Relacionamento e Comunicação, implementação de indicadores de desempenho por área e na adequação da estrutura organizacional com a contratação de um Diretor Geral e na implantação de um conselho de administração. Estas ações favoreceram a melhoria do resultado econômico, que passou a ser positivo, e na reversão da saída de alunos que tinham acentuada tendências de queda.'
      }
    },
    {
      id: 2,
      category: 'B2C',
      title: 'BurgerCue',
      image: './assets/img/portfolio/burger.jpg',
      description: 'Conheça a jornada da BurgerCue, desde sua origem até o plano de expansão nacional, com projeções e estratégias para conquistar o mercado brasileiro.',
      details: {
        client: 'A BurgerCue é uma empresa que iniciou suas atividades no início de 2019, utilizando a estrutura física do restaurante Fogo e Chama, em Natal – RN, para venda de burgers produzidos ao fogo, na parrilla. A iniciativa foi um sucesso, o produto foi muito bem aceito pelos clientes, o cardápio de burgers foi ampliado e tem sido hoje uma ótima opção de venda do restaurante – principalmente no delivery, em tempos de pandemia. Paulo Gallindo é o criador e fundador da BurgerCue em Natal. Seguindo sua intuição de empreendedor e ao perceber o potencial da marca para novos mercados foi à São Paulo. Logo observou a boa aceitação do público e iniciou a produção e entrega de kits de hamburguers para serem feitos em casa. O foco foi testar as cidades de São Paulo e Rio de Janeiro.',
        challenge: 'Paulo decidiu então elaborar um Plano de Negócios que objetivava ampliar sua atuação no sudeste brasileiro, com o suporte da VBMC Consultores. A missão da VBMC neste primeiro momento foi dar suporte à realização de estudos preliminares de mercado, conceituação do modelo de negócios e viabilidade econômica e financeira. Todos os estudos foram compilados em um Plano de Negócios objetivo e atrativo a investidores.',
        solution: 'A BurgerCue, com o suporte da VBMC Consultores, desenvolveu pesquisas no setor de Food Service brasileiro e veiculou pesquisas de concorrentes. Criou então a matriz de produtos com os respectivos diferenciais e os processos operacionais e de gestão. Na próxima etapa formulou as estratégias de marketing e vendas no que se refere à localização, preço e comunicação com o público-alvo, a partir do desenvolvimento das personas. Definiu o planejamento estratégico e de expansão para a realização da visão BurgerCue, por meio de análise SWOT e outras ferramentas. Por fim, estabeleceu a estrutura de custos dos produtos, formação de preços e despesas, e construiu também indicadores econômicos e financeiros e projeções como propostas para investidores.',
        results: 'Depois de ter passado pelo período “pré-seed”, utilizando as instalações de Natal como laboratório e sua experiência de venda de kits caseiros em São Paulo e Rio de Janeiro, a BurgerCue deseja agora partir para a fase “seed”, instalando 3 dark kitchens nos primeiros 8 meses de operação no sudeste. No Business Plan BurgerCue, as projeções econômicas foram traçadas com cautela e apresentam margens operacionais e pay back atrativos, com break even atingido já no segundo mês de operação. Vale ressaltar que, devido ao baixo custo de investimento, boa margem operacional e às oportunidades oferecidas pelo mercado, a BurgerCue possui escalabilidade e desenvolverá nos próximos 6 meses um plano de expansão para todo território brasileiro.'
      }
    },
    {
      id: 3,
      category: 'B2C',
      title: 'GO Eyewear',
      image: './assets/img/portfolio/go-cases.jpg',
      description: 'Descubra como a GO Eyewear se transformou em um dos maiores players do mercado óptico brasileiro, com crescimento anual impressionante e gestão comercial de alta performance.',
      details: {
        client: 'A GO Eyewear é uma empresa brasileira que cria, produz e distribui óculos de sol e de receituário de marcas que são referências de design, tecnologia e qualidade no Brasil e no mundo. Há mais de 15 anos no setor, a GO apresenta inovação a cada coleção de suas marcas.',
        challenge: 'Após um intenso diagnóstico empresarial realizado no final de 2006, em 2007 o desafio estava lançado: a conquista, manutenção e o desenvolvimento da estrutura e processos de vendas para que fosse possível o acompanhamento do crescimento constante do mercado óptico no Brasil. Os potenciais de ganho deste projeto estavam basicamente ancorados em ampliar a frequência de compra dos clientes atuais e ampliar a carteira de clientes em áreas dos países ainda pouco exploradas.',
        solution: 'Para isso, a VBMC Consultores montou um projeto de Gestão Comercial para revigorar e principalmente ampliar a estrutura de representantes, capacitando-a para uma atuação moderna, com processos e ferramentas ágeis e eficazes de gestão de vendas. Foram iniciados programas de Gestão de Vendas, Estruturação de Representantes, Treinamento de Vendas e  Gestão de Marketing. Paralelamente iniciou-se na GO Eyewear um processo de profissionalização por meio da implantação de ferramentas e metodologias de gestão em áreas de apoio com o objetivo de uma atuação focada no crescimento e lucratividade. Foi construído um grupo executivo de diretores, subordinados à CEO, que recebiam coaching individual e participavam de programas de capacitação. Aos poucos, este grupo foi tomando corpo e cada vez mais participando do planejamento e das diretrizes principais da empresa como um todo.',
        results: 'Já no segundo ano de projeto de consultoria o faturamento da empresa crescia na ordem de 25% ao ano, contra crescimentos negativos em períodos anteriores. A VBMC Consultores ao longo destes últimos 10 anos desenvolveu trabalhos na GO em diversas áreas como Gestão de Pessoas, Gestão de Produtos e Finanças. Hoje a GO Eyewear está entre os maiores players do segmento óptico brasileiro, produzindo e distribuindo marcas relevantes como Ana Hickman, Bulget, Atitude, Speedo, Cartier, Gucci e muitas outras.'
      }
    },
    {
      id: 4,
      category: 'B2C',
      title: 'Óticas Diniz',
      image: './assets/img/portfolio/diniz.jpg',
      description: 'Descubra como a Óticas Diniz superou desafios de crescimento e ineficiência com uma transformação estratégica que impulsionou vendas e profissionalizou a gestão.',
      details: {
        client: 'A Óticas Diniz – Porto Alegre é uma rede de franquias da Óticas Diniz que atua na Região Metropolitana de Porto Alegre desde 2008. A rede de varejo possui 24 óticas, comercializa diversas marcas e grifes nacionais e internacionais de armações e lentes e está entre as marcas mais lembradas da região com diversos prêmios de Top of Mind.',
        challenge: 'Após um grande período de crescimento em volume de lojas e faturamento desde sua inauguração, a família proprietária e responsável pela alta administração da empresa, percebeu, em 2016, que não seria possível conviver com a falta de controle e com o alto índice de ineficiência nos processos, portanto era o momento de partir para a profissionalização da empresa. Além disso, com a estagnação das vendas, era necessário urgentemente a retomada do crescimento, para que não houvesse impactos severos no caixa.',
        solution: 'A Óticas Diniz Porto Alegre contratou a VBMC Consultores para iniciar em 2017 um projeto de consultoria, com o objetivo de resgatar o crescimento nas vendas através da implantação de processo de gestão de vendas nas lojas. Além disso, por meio da implantação de processos de gestão nas áreas de apoio, como Financeira e Estoque, demos um primeiro passo para a profissionalização controlando custos e despesas e acompanhando os resultados operacionais da empresa loja a loja.',
        results: 'No 2º semestre de 2017, o resgate do crescimento nas vendas foi iniciado e a empresa saiu da estagnação do 1º semestre para um crescimento superior a 8%. Com a implantação de processos de gestão, os primeiros controles e monitoramentos foram implementados, algumas despesas foram reduzidas e melhor controladas. Paralelamente, iniciou-se um processo de mudança cultural (profissionalização) que partiu da alta administração para os demais colaboradores. Na prática, percebeu-se grandes mudanças na forma de se comunicar, na forma de conduzir reuniões e na forma de administrar o tempo de trabalho em todos os níveis. No final de 2017, a família decidiu dar um segundo passo e, depois de ter consolidado os métodos de gestão de vendas, contratou a VBMC Consultores novamente para redesenhar todos os processos internos e ampliar a gestão através de indicadores de desempenho para todas as áreas. Em 2018, o crescimento das vendas atingiu um patamar superior a 12% e a alta administração está sendo preparada para monitorar o desempenho operacional de todas as áreas, por meio de relatórios de gestão desenvolvidos em plataformas de BI, que possibilitam a tomada de ação com eficiência.'
      }
    },
    {
      id: 5,
      category: 'B2B',
      title: 'Tok&Stok B2B',
      image: './assets/img/portfolio/tokstok.jpg',
      description: 'Descubra como a Tok&Stok transformou seu modelo de negócios com uma estratégia de vendas B2B inovadora, que trouxe resultados surpreendentes e consolidou a marca no mercado corporativo.',
      details: {
        client: 'A Tok&Stok surgiu em 1978, fruto do empreendedorismo do casal, Régis e Ghislaine Dubrule, recém-chegado da França ao Brasil. Hoje é uma rede de varejo de móveis e decoração referência no país e presente em várias cidades brasileiras.',
        challenge: 'Desde o início a Tok&Stok passou por períodos de grande crescimento em volume de lojas e faturamento e a VBMC Consultores esteve presente em projetos nas áreas de Gestão de Lojas, Gestão de Produtos e Gestão de Pessoas. Em meados de 2003 os sócios da VBMC Consultores apresentaram pela primeira vez ao casal fundador (Ghislaine e Régis) uma oportunidade de desenvolvimento de uma área que pudesse oferecer aos seus clientes corporativos um serviço especializado. Permeada por um plano de negócios muito bem detalhado e desenvolvido a quatro mãos, nasce em setembro de 2004 a área de Vendas Corporativas (B2B) da Tok&Stok para atender empresas e arquitetos de interiores da Grande São Paulo.',
        solution: 'A VBMC Consultores definiu e implantou estratégia e políticas comerciais; os processos de prospecção e relacionamento com os clientes; contratou, capacitou, definiu esteira salarial e programa de remuneração de toda equipe de vendas; estruturou equipe de backoffice; desenvolveu e implantou todos os processos de gestão e assumiu interinamente a área. Mensalmente a equipe da VBMC Consultores responsável pelo projeto, reportava diretamente ao casal Dubrule os resultados da nova área de Vendas Corporativas.',
        results: 'Em 2017 a área de Vendas Corporativas da Tok&Stok trouxe um incremento de faturamento bruto de 10 milhões com margem de contribuição líquida de 22%. Através da área de vendas corporativas (B2B), a Tok&Stok conseguiu aprimorar muito o seu portfólio de mobiliário corporativo e a sua prestação de serviços para empresas e arquitetos da Grande São Paulo.'
      }
    },
  ];

  const resultadosData = [
    { valor: '128', descricao: 'Empresas atendidas' },
    { valor: '367', descricao: 'Projetos desenvolvidos'},
    { valor: '27.136', descricao: 'Profissionais treinados'},
    { valor: 'R$1,19 BI', descricao: 'Resultados gerados nos projetos'},
    { valor: '3 a 10', descricao: 'ROI – Retorno sobre investimento'}
  ];

  // const [activeTab, setActiveTab] = useState('profile');

  useEffect(() => {
    // Configura Scroll Reveal
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '16px',
      duration: 1000,
      reset: false,
    });
    sr.reveal('.scroll-revealed', { cleanup: true });
  
    // Configura GLightbox para vídeos locais
    const lightbox = GLightbox({
      selector: '.video-popup',
      href: '/imgs/VSL.mp4',
      type: 'video',
      source: 'local',
      width: 900,
      autoplayVideos: true,
      loop: false,
      slideEffect: 'none',
      hideControls: true,
      description: `
        <div style="text-align: center; margin-top: 15px;">
          <a href="#" 
            onclick="document.querySelector('.gclose').click(); document.querySelector('#formSection').scrollIntoView({ behavior: 'smooth' }); return false;" 
            class="button-class" 
            style="display: inline-block; padding: 10px 20px; background-color: #3d63dd; color: white; text-decoration: none; border-radius: 5px;">
            Fale com um especialista
          </a>
        </div>
      `,
    });
  
    // Função para fechar o lightbox e rolar até a seção do formulário
    window.closeLightboxAndScroll = function() {
      lightbox.close();
      document.querySelector('#formSection').scrollIntoView({ behavior: 'smooth' });
    }; 

    // Configura GLightbox para galeria de imagens
    GLightbox({
      selector: '.portfolio-box',
      type: 'image',
      width: 900,
    });  

    // Configura Swiper para o carrossel de testemunhos
    const testimonialSwiper = new Swiper('.testimonial-carousel', {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        640: { slidesPerView: 2, spaceBetween: 30 },
        1024: { slidesPerView: 3, spaceBetween: 30 },
        1280: { slidesPerView: 3, spaceBetween: 30 },
      },
    });

    // Cleanup function para Swiper e GLightbox, caso necessário
    return () => {
      sr.destroy();
      testimonialSwiper.destroy();
    };
  }, []);

  return (
    <body>
    <main class="main relative">
      {/* <!-- Hero section --> */}
      <section
        id="home"
        class="relative overflow-hidden bg-[#37bdc6] text-white pt-[120px] md:pt-[130px] lg:pt-[160px]"
      >
        <div class="container">
          <div class="-mx-5 flex flex-wrap items-center">
            <div class="w-full px-5">
              <div class="scroll-revealed mx-auto max-w-[780px] text-center">
                <h1
                  class="mb-6 text-white text-3xl font-bold leading-snug sm:text-4xl lg:text-5xl"
                >
                  Revolucione seu negócio com estratégias disruptivas
                </h1>
                <p
                  class="mx-auto mb-9 max-w-[600px] text-gray-200 text-base sm:text-lg"
                >
                  Se você procurou por consultoria empresarial, assessoria em gestão, análise de mercado ou inovação – chegou ao lugar certo. Nossa metodologia exclusiva transforma desafios em resultados reais e mensuráveis, elevando sua empresa a novos patamares.
                </p>
                <ul
                  class="mb-10 flex flex-wrap items-center justify-center gap-4"
                >
                  <li>
                    <a
                      href='/performance'
                      onClick={handleOpenModal}                      
                      class="inline-flex items-center justify-center rounded-md bg-white text-[#37bdc6] px-5 py-3 text-base font-medium shadow-md hover:bg-gray-200 hover:text-black"
                      role="button"
                    >
                      Fale com um especialista
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-full px-5">
              <div class="scroll-revealed relative z-10 mx-auto max-w-[845px]">
                <figure class="mt-16">
                  <img
                    src="https://futureconsult.com.br/website2021/wp-content/uploads/2020/08/consultoria-empresarial-fia-o-que-e.jpeg"
                    class="mx-auto max-w-full rounded-t-xl"
                    alt="Ilustração moderna representando estratégias empresariais"
                  />
                </figure>
                <div class="absolute -left-9 bottom-0 z-[-1]">
                  <img
                    src="./assets/img/dots.svg"
                    class="w-[120px] opacity-75"
                    alt=""
                  />
                </div>
                <div class="absolute -right-6 -top-6 z-[-1]">
                  <img
                    src="./assets/img/dots.svg"
                    class="w-[120px] opacity-75"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Portfolio section --> */}
      <section id="portfolio" className="section-area">
        <div className="container">
          <div className="text-center max-w-[550px] mx-auto mb-12">
            <h6 className="mb-2 text-lg font-semibold text-[#37bdc6]">Portfólio</h6>
            <h2 className="mb-6">Conheça nossos cases de sucesso</h2>
          </div>

          <div className="portfolio-grid row">
            {portfolioItems.map((item) => (
              <div className="portfolio col-12 sm:col-6 lg:col-4" key={item.id}>
                <article className="group">
                  <div className="relative overflow-hidden w-full aspect-[4/3] rounded-xl">
                    <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 opacity-0 group-hover:opacity-100 transition">
                      <button
                        onClick={() => openModal(item)}
                        className="text-white bg-[#37bdc6] px-4 py-2 rounded-lg text-lg hover:bg-primary-light-10"
                      >
                        Ver detalhes
                      </button>
                    </div>
                  </div>
                  <div className="pt-4">
                    <h4 className="mb-2">
                      <button onClick={() => openModal(item)} className="text-[1.5rem] leading-tight text-inherit">
                        {item.title}
                      </button>
                    </h4>
                    <p>{item.description}</p>
                  </div>
                </article>
              </div>
            ))}
          </div>

            <div class='text-center pt-8'>
              <a
                      href='/performance'
                      onClick={handleOpenModal}                      
                      class="inline-flex items-center justify-center rounded-md bg-[#37bdc6] text-white px-5 py-3 text-base font-medium shadow-md hover:bg-[#37bdc6]/50"
                      role="button"
                    >
                      Fale com um especialista
              </a>
            </div>


          {/* Modal */}
          {isOpen && selectedItem && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white max-w-3xl w-full lg:w-3/4 p-6 rounded-lg shadow-lg overflow-y-auto max-h-[90vh] relative">
                <button onClick={closeModal} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl">
                  &times;
                </button>

                <div className="lg:flex lg:items-start gap-4 mb-6">
                  <img src={selectedItem.image} alt={selectedItem.title} className="w-full lg:w-1/3 rounded-lg mb-4 lg:mb-0" />
                  <div className="lg:w-2/3">
                    <h3 className="text-xl font-semibold mb-2">{selectedItem.title}</h3>
                    <p className="text-gray-700"><strong>Cliente:</strong> {selectedItem.details.client}</p>
                  </div>
                </div>

                <div>
                  <h4 className="text-lg font-semibold text-[#37bdc6] mb-2">Desafio</h4>
                  <p className="text-gray-700 mb-4">{selectedItem.details.challenge}</p>

                  <h4 className="text-lg font-semibold text-[#37bdc6] mb-2">Solução</h4>
                  <p className="text-gray-700 mb-4">{selectedItem.details.solution}</p>

                  <h4 className="text-lg font-semibold text-[#37bdc6] mb-2">Resultados Alcançados</h4>
                  <p className="text-gray-700">{selectedItem.details.results}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* <!-- Services section --> */}
      <section id="services" className="section-area">
        <div className="container">
          <div className="scroll-revealed text-center max-w-[550px] mx-auto mb-12">
            <h6 className="mb-2 block text-lg font-semibold text-[#37bdc6]">Serviços</h6>
            <h2 className="mb-6">Nossas soluções corporativas</h2>
          </div>

          <div className="row">
            <div className="scroll-revealed col-12 sm:col-6 lg:col-4">
              <div className="group hover:-translate-y-1">
                <div className="w-[70px] h-[70px] rounded-2xl mb-6 flex items-center justify-center text-[37px] bg-[#37bdc6] text-black">
                  <AiFillAppstore />
                </div>
                <div className="w-full">
                  <h4 className="text-[1.25rem] font-semibold mb-5">Gestão de Vendas</h4>
                  <p>Você está satisfeito com o seu volume de vendas atual? Você possui boas informações gerenciais de vendas? A sua equipe de vendas está bem dimensionada e preparada?</p>
                </div>
              </div>
            </div>

            <div className="scroll-revealed col-12 sm:col-6 lg:col-4">
              <div className="group hover:-translate-y-1">
                <div className="w-[70px] h-[70px] rounded-2xl mb-6 flex items-center justify-center text-[37px] bg-[#37bdc6] text-black">
                  <AiOutlineDollarCircle />
                </div>
                <div className="w-full">
                  <h4 className="text-[1.25rem] font-semibold mb-5">Gestão Financeira</h4>
                  <p>Você analisa os resultados econômicos e financeiros da sua empresa? Seu faturamento é bom, mas não sobra dinheiro no caixa? Você consegue controlar bem seus custos e despesas?</p>
                </div>
              </div>
            </div>

            <div className="scroll-revealed col-12 sm:col-6 lg:col-4">
              <div className="group hover:-translate-y-1">
                <div className="w-[70px] h-[70px] rounded-2xl mb-6 flex items-center justify-center text-[37px] bg-[#37bdc6] text-black">
                  <AiFillGift />
                </div>
                <div className="w-full">
                  <h4 className="text-[1.25rem] font-semibold mb-5">Gestão Estratégica</h4>
                  <p>Sua empresa sabe muito bem onde quer chegar? Você utiliza bons indicadores de desempenho nas áreas? Sua empresa está preparada para perpetuar sem a sua participação?</p>
                </div>
              </div>
            </div>

            <div className="scroll-revealed col-12 sm:col-6 lg:col-4">
              <div className="group hover:-translate-y-1">
                <div className="w-[70px] h-[70px] rounded-2xl mb-6 flex items-center justify-center text-[37px] bg-[#37bdc6] text-black">
                  <AiOutlineLayout />
                </div>
                <div className="w-full">
                  <h4 className="text-[1.25rem] font-semibold mb-5">Gestão da Produção</h4>
                  <p>Sua produção tem muitas perdas e paradas? Você gerencia a sua manutenção produtiva? Você controla o seu processo produtivo?</p>
                </div>
              </div>
            </div>

            <div className="scroll-revealed col-12 sm:col-6 lg:col-4">
              <div className="group hover:-translate-y-1">
                <div className="w-[70px] h-[70px] rounded-2xl mb-6 flex items-center justify-center text-[37px] bg-[#37bdc6] text-black">
                  <AiOutlineCluster />
                </div>
                <div className="w-full">
                  <h4 className="text-[1.25rem] font-semibold mb-5">Gestão de Suprimentos</h4>
                  <p>Você consegue comprar com melhor preço e prazo? Você controla bem os seus estoques de matéria prima e insumos? Você deixa de atender os seus clientes por falta de estoque?</p>
                </div>
              </div>
            </div>

            <div className="scroll-revealed col-12 sm:col-6 lg:col-4">
              <div className="group hover:-translate-y-1">
                <div className="w-[70px] h-[70px] rounded-2xl mb-6 flex items-center justify-center text-[37px] bg-[#37bdc6] text-black">
                  <AiOutlineTeam />
                </div>
                <div className="w-full">
                  <h4 className="text-[1.25rem] font-semibold mb-5">Gestão de Pessoas</h4>
                  <p>A sua área de GDP contribui para os resultados da sua empresa? Você consegue atrair e reter talentos? Você possui programas de capacitação de equipes?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Testimonials section --> */}
      <section id="testimonials" className="section-area">
        <div className="container">
          <div className="scroll-revealed text-center max-w-[550px] mx-auto mb-12">
            <h6 className="mb-2 block text-lg font-semibold text-[#37bdc6]">
              Clientes satisfeitos
            </h6>
            <h2 className="mb-6">O que nossos clientes falam</h2>
          </div>

          <div className="swiper testimonial-carousel common-carousel scroll-revealed">
            <div className="swiper-wrapper">
              {/* Testimonial 1 */}
              <div className="swiper-slide">
                <div className="rounded-xl bg-body-light-1 dark:bg-body-dark-12/10 px-5 py-8 shadow-card-2 sm:px-8">
                  <p className="mb-6 text-base text-body-light-11 dark:text-body-dark-11">
                    "Iniciamos com a VBMC um programa de estruturação e profissionalização de nossa empresa em todas as áreas que comporta uma franqueadora como as Óticas Diniz. Além do conhecimento que os consultores possuem, percebemos a proatividade em resolver os problemas e envolvimento na cultura da empresa. É uma equipe que mergulha para entender todas as questões e conseguem se adaptar para conduzir as mudanças da melhor maneira. A VBMC entende também que é necessário não somente resolver os problemas que estamos vivenciando, mas capacitar com novas ferramentas e habilidades os gestores e colaboradores como passo fundamental do processo de consultoria. E como sucessora da empresa, estar ao lado da consultoria VBMC está sendo uma experiência fundamental para o meu avanço profissional."
                  </p>
                  <figure className="flex items-center gap-4">
                    <div className="h-[50px] w-[50px] rounded-full overflow-hidden flex-shrink-0">
                      <img
                        src="./assets/img/avatar/ariane.jpeg"
                        alt="Ariane Diniz"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <figcaption className="flex-grow">
                      <h3 className="text-sm font-semibold text-body-light-11 dark:text-body-dark-11">
                        Ariane Diniz
                      </h3>
                      <p className="text-xs text-body-light-10 dark:text-body-dark-10">
                        Diretora e sucessora das Óticas Diniz
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>

              {/* Testimonial 2 */}
              <div className="swiper-slide">
                <div className="rounded-xl bg-body-light-1 dark:bg-body-dark-12/10 px-5 py-8 shadow-card-2 sm:px-8">
                  <p className="mb-6 text-base text-body-light-11 dark:text-body-dark-11">
                    "Encontramos na VBMC uma parceria de confiança e cumplicidade, onde todos os processos e implantações foram analisados e personalizados as nossas necessidades, o que está gerando processo tranquilo de reestruturação da empresa."
                  </p>
                  <figure className="flex items-center gap-4">
                    <div className="h-[50px] w-[50px] rounded-full overflow-hidden flex-shrink-0">
                      <img
                        src="./assets/img/avatar/regina.jpeg"
                        alt="Regina Carvajal"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <figcaption className="flex-grow">
                      <h3 className="text-sm font-semibold text-body-light-11 dark:text-body-dark-11">
                        Regina Carvajal
                      </h3>
                      <p className="text-xs text-body-light-10 dark:text-body-dark-10">
                        Diretora e proprietária da Eletrosol Equipamentos Elétricos
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>

              {/* Testimonial 3 - Video Testimonial */}
              <div className="swiper-slide">
                <div className="rounded-xl bg-body-light-1 dark:bg-body-dark-12/10 px-5 py-8 shadow-card-2 sm:px-8">
                  <div className="mb-6 aspect-[9/16] overflow-hidden rounded-lg">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/p073z1E7MH8"
                      title="Video testimonial"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="h-full w-full object-cover"
                    ></iframe>
                  </div>
                  <figure className="flex items-center gap-4">
                    <div className="h-[50px] w-[50px] rounded-full overflow-hidden flex-shrink-0">
                      <img
                        src="./assets/img/avatar/paulinho.jpeg"
                        alt="Paulo Gallindo"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <figcaption className="flex-grow">
                      <h3 className="text-sm font-semibold text-body-light-11 dark:text-body-dark-11">
                        Paulo Gallindo
                      </h3>
                      <p className="text-xs text-body-light-10 dark:text-body-dark-10">
                        Fundador do Burgercue
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>

            <div className="mt-[60px] flex items-center justify-center gap-1">
              <div className="swiper-button-prev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[#37bdc6]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div className="swiper-button-next">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[#37bdc6]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

            {/* Frase Rodrigo */}
            <section id="rodrigo-quote" className="section-area bg-[#f3f4f6] py-20">
              <div className="container mx-auto text-center">
                <div className="scroll-revealed mx-auto max-w-[850px]">
                  <figure className="flex flex-col items-center">
                    <img
                      src="./assets/img/avatar/Rodrigo-de-paula-2.jpg"
                      alt="Rodrigo de Paula"
                      className="h-[180px] w-[180px] rounded-full object-cover shadow-lg mb-8"
                    />
                    <figcaption>
                      <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                        Rodrigo de Paula
                      </h3>
                      <p className="text-base font-light text-gray-600">
                        Sócio CEO
                      </p>
                    </figcaption>
                  </figure>
                  
                  <blockquote className="mt-8 px-8 py-4 bg-[#37bdc6] text-white rounded-xl shadow-lg max-w-[700px] mx-auto">
                    <p className="text-lg italic font-medium">
                      "O empresário precisa não só estruturar os processos da sua empresa, mas criar rituais de gestão para monitoramento e delegação com a sua liderança. Com esse exemplo, eles estarão preparados para direcionar suas próprias equipes com eficiência. Somente assim você vai conseguir olhar para o seu negócio com olhar estratégico e cuidado que ele merece."
                    </p>
                  </blockquote>
                </div>
              </div>
      </section>


      {/* <!-- Clients section --> */}
      <section id="clients" class="section-area">
        <div class="container">
          <div class="scroll-revealed text-center max-w-[550px] mx-auto mb-12">
            <h6 class="mb-2 block text-lg font-semibold text-[#37bdc6]">Clientes</h6>
            <h2 class="mb-6">Nossos clientes incríveis!</h2>
            <p>Desde 1983 a VBMC Consultores já realizou mais de 367 projetos em empresas de diversos segmentos.</p>
          </div>

          <div class="">
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/Bic.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/diniz-franchising.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/MC.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/tokstok.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/visconti.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/lourenço-castanho.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/tng.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
              <div class="scroll-revealed text-center p-4">
                <img src="./assets/img/brand/Celite.jpg" alt="" class="h-[120px] inline-block grayscale dark:invert hover:grayscale-0 hover:invert-0" />
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- About section --> */}
      <section id="about" class="section-area">
        <div class="container">
          <div class="grid grid-cols-1 gap-14 lg:grid-cols-2">
            <div class="w-full">
              <figure class="scroll-revealed max-w-[480px] mx-auto">
                <img
                  src="./assets/img/about-img.jpeg"
                  alt=""
                  class="rounded-xl"
                />
              </figure>
            </div>

            <div class="w-full">
              <div class="scroll-revealed">
                <h6 class="mb-2 block text-lg font-semibold text-[#37bdc6]">
                  Sobre nós
                </h6>
                <h2 class="mb-6">
                Uma empresa de Consultoria de Gestão Empresarial que promove resultados
                </h2>
              </div>
              <p>
                    A VBMC Consultores foi criada em julho de 1983 com o objetivo de promover, em seus clientes, resultados econômicos através do desenvolvimento e implantação de programas personalizados de produtividade e eficácia organizacional.
                    </p>
                    <p>
                    Ao longo destes anos no mercado a VBMC Consultores aprimorou e ampliou sua atuação tornando-se uma empresa com grande credibilidade, atualizada e criativa, firmando-se
                    como uma organização ética, capacitada a contribuir para o desenvolvimento, melhoria da competitividade empresarial, consolidação e perpetuação de seus clientes. Nossa metodologia está baseada no tripé: Formulação de Estratégias de Ação; Gestão e Desenvolvimento do Capital Humano; e Tecnologia em Gestão de Resultados. Nossos projetos apresentam um retorno superior a 3 vezes o investimento realizado.                    </p>
                  

              {/* <div className="tabs scroll-revealed">
                <nav
                  className="tabs-nav flex flex-wrap gap-4 my-8"
                  role="tablist"
                  aria-label="About us tabs"
                >
                  <button
                    type="button"
                    className={`tabs-link inline-block py-2 px-4 rounded-md text-body-light-12 dark:text-body-dark-12 bg-body-light-12/10 dark:bg-body-dark-12/10 text-inherit font-medium hover:bg-[#37bdc6] hover:text-white focus:bg-[#37bdc6] focus:text-[#37bdc6] ${
                      activeTab === 'profile' ? 'bg-[#37bdc6] text-black' : ''
                    }`}
                    onClick={() => setActiveTab('profile')}
                    id="tabs-list-profile"
                    role="tab"
                    aria-controls="tabs-panel-profile"
                    aria-selected={activeTab === 'profile'}
                  >
                    Our Profile
                  </button>

                  <button
                    type="button"
                    className={`tabs-link inline-block py-2 px-4 rounded-md text-body-light-12 dark:text-body-dark-12 bg-body-light-12/10 dark:bg-body-dark-12/10 text-inherit font-medium hover:bg-primary hover:text-[#37bdc6]-color focus:bg-primary focus:text-[#37bdc6]-color ${
                      activeTab === 'vision' ? 'bg-primary text-[#37bdc6]' : ''
                    }`}
                    onClick={() => setActiveTab('vision')}
                    id="tabs-list-vision"
                    role="tab"
                    aria-controls="tabs-panel-vision"
                    aria-selected={activeTab === 'vision'}
                  >
                    Our Vision
                  </button>

                  <button
                    type="button"
                    className={`tabs-link inline-block py-2 px-4 rounded-md text-body-light-12 dark:text-body-dark-12 bg-body-light-12/10 dark:bg-body-dark-12/10 text-inherit font-medium hover:bg-primary hover:text-[#37bdc6]-color focus:bg-primary focus:text-[#37bdc6]-color ${
                      activeTab === 'history' ? 'bg-primary text-[#37bdc6]' : ''
                    }`}
                    onClick={() => setActiveTab('history')}
                    id="tabs-list-history"
                    role="tab"
                    aria-controls="tabs-panel-history"
                    aria-selected={activeTab === 'history'}
                  >
                    Our History
                  </button>
                </nav>

                {activeTab === 'profile' && (
                  <div
                    className="tabs-content mt-4"
                    id="tabs-panel-profile"
                    tabIndex="-1"
                    role="tabpanel"
                    aria-labelledby="tabs-list-profile"
                  >
                    <p>
                    A VBMC Consultores foi criada em julho de 1983 com o objetivo de promover, em seus clientes, resultados econômicos através do desenvolvimento e implantação de programas personalizados de produtividade e eficácia organizacional.
                    </p>
                    <p>
                    Ao longo destes anos no mercado a VBMC Consultores aprimorou e ampliou sua atuação tornando-se uma empresa com grande credibilidade, atualizada e criativa, firmando-se
                    como uma organização ética, capacitada a contribuir para o desenvolvimento, melhoria da competitividade empresarial, consolidação e perpetuação de seus clientes. Nossa metodologia está baseada no tripé: Formulação de Estratégias de Ação; Gestão e Desenvolvimento do Capital Humano; e Tecnologia em Gestão de Resultados. Nossos projetos apresentam um retorno superior a 3 vezes o investimento realizado.                    </p>
                  </div>
                )}

                {activeTab === 'vision' && (
                  <div
                    className="tabs-content mt-4"
                    id="tabs-panel-vision"
                    tabIndex="-1"
                    role="tabpanel"
                    aria-labelledby="tabs-list-vision"
                  >
                    <p>
                      Aba 2
                    </p>
                    <p>
                      There are many variations of passages of Lorem Ipsum available, but the majority have in some form, by injected humour.
                    </p>
                  </div>
                )}

                {activeTab === 'history' && (
                  <div
                    className="tabs-content mt-4"
                    id="tabs-panel-history"
                    tabIndex="-1"
                    role="tabpanel"
                    aria-labelledby="tabs-list-history"
                  >
                    <p>
                      Aba 3
                    </p>
                    <p>
                      There are many variations of passages of Lorem Ipsum available, but the majority have in some form, by injected humour.
                    </p>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </section>


      <section id="call-action" className="section-area !bg-[#37bdc6] text-white">
        <div className="container">
          <div className="scroll-revealed text-center max-w-[550px] mx-auto mb-12">
            <h6 className="mb-2 block text-lg text-white font-semibold">Resultados</h6>
            <h2 className="mb-6 text-white">Nosso impacto em números</h2>
            <p className='text-gray-500'>Confira o impacto que nossos projetos geraram para nossos clientes e parceiros.</p>
          </div>
          
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6">
            {resultadosData.map((item, index) => (
              <div key={index} className="scroll-revealed text-center p-6 bg-white text-[#37bdc6] rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out">
                <h3 className="text-2xl font-bold">{item.valor}</h3>
                <p className="text-sm">{item.descricao}</p>
              </div>
            ))}
          </div>
        </div>
      </section>



      {/* <!-- Team section --> */}
      <section id="team" class="section-area">
        <div class="container">
          <div class="scroll-revealed text-center max-w-[550px] mx-auto mb-12">
            <h6 class="mb-2 block text-lg font-semibold text-[#37bdc6]">Equipe</h6>
            <h2 class="mb-6">Conheça seus consultores</h2>
            {/* <p>
              There are many variations of passages of Lorem Ipsum available but
              the majority have suffered alteration in some form.
            </p> */}
          </div>

          <div class="row">
            <div class="scroll-revealed col-12 sm:col-6 md:col-4 lg:col-3">
              <figure
                class="group rounded-xl bg-body-light-1 dark:bg-body-dark-12/10 px-5 pb-10 pt-12 shadow-card-2 hover:shadow-lg hover:-translate-y-1"
              >
                <div class="relative z-10 mx-auto mb-5 h-[120px] w-[120px]">
                  <img
                    src="./assets/img/avatar/antonio.jpg"
                    alt=""
                    class="h-full w-full rounded-full object-cover"
                  />
                  <span
                    class="absolute bottom-0 left-0 -z-10 h-10 w-10 rounded-full bg-red-500 opacity-0 group-hover:opacity-100"
                  ></span>
                  <span
                    class="absolute top-0 right-0 -z-10 h-10 w-10 rounded-full bg-blue-500 opacity-0 group-hover:opacity-100"
                  ></span>
                </div>
                <figcaption class="text-center block">
                  <h4
                    class="mb-1 text-lg font-semibold text-body-light-12 dark:text-body-dark-12"
                  >
                    Antônio Arice
                  </h4>
                  <p
                    class="mb-5 text-sm text-body-light-11 dark:text-body-dark-11"
                  >
                    Sócio CTO
                  </p>
                  <div className="flex items-center justify-center gap-5">
                    <a
                      href="https://www.linkedin.com/in/antônio-carlos-arice-73689828/"
                      className="text-body-light-10 dark:text-body-dark-10 hover:text-[#37bdc6]"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </figcaption>
              </figure>
            </div>

            <div class="scroll-revealed col-12 sm:col-6 md:col-4 lg:col-3">
              <figure
                class="group rounded-xl bg-body-light-1 dark:bg-body-dark-12/10 px-5 pb-10 pt-12 shadow-card-2 hover:shadow-lg hover:-translate-y-1"
              >
                <div class="relative z-10 mx-auto mb-5 h-[120px] w-[120px]">
                  <img
                    src="./assets/img/avatar/Rodrigo-de-paula-2.jpg"
                    alt=""
                    class="h-full w-full rounded-full object-cover"
                  />
                  <span
                    class="absolute bottom-0 left-0 -z-10 h-10 w-10 rounded-full bg-red-500 opacity-0 group-hover:opacity-100"
                  ></span>
                  <span
                    class="absolute top-0 right-0 -z-10 h-10 w-10 rounded-full bg-blue-500 opacity-0 group-hover:opacity-100"
                  ></span>
                </div>
                <figcaption class="text-center block">
                  <h4
                    class="mb-1 text-lg font-semibold text-body-light-12 dark:text-body-dark-12"
                  >
                    Rodrigo de Paula
                  </h4>
                  <p
                    class="mb-5 text-sm text-body-light-11 dark:text-body-dark-11"
                  >
                    Sócio CEO
                  </p>
                  <div className="flex items-center justify-center gap-5">
                    <a
                      href="https://www.linkedin.com/in/rodrigo-de-paula-106b0932/"
                      className="text-body-light-10 dark:text-body-dark-10 hover:text-[#37bdc6]"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </figcaption>
              </figure>
            </div>

            <div class="scroll-revealed col-12 sm:col-6 md:col-4 lg:col-3">
              <figure
                class="group rounded-xl bg-body-light-1 dark:bg-body-dark-12/10 px-5 pb-10 pt-12 shadow-card-2 hover:shadow-lg hover:-translate-y-1"
              >
                <div class="relative z-10 mx-auto mb-5 h-[120px] w-[120px]">
                  <img
                    src="./assets/img/avatar/Jorge.jpg"
                    alt=""
                    class="h-full w-full rounded-full object-cover"
                  />
                  <span
                    class="absolute bottom-0 left-0 -z-10 h-10 w-10 rounded-full bg-red-500 opacity-0 group-hover:opacity-100"
                  ></span>
                  <span
                    class="absolute top-0 right-0 -z-10 h-10 w-10 rounded-full bg-blue-500 opacity-0 group-hover:opacity-100"
                  ></span>
                </div>
                <figcaption class="text-center block">
                  <h4
                    class="mb-1 text-lg font-semibold text-body-light-12 dark:text-body-dark-12"
                  >
                    Jorge Luís Motta
                  </h4>
                  <p
                    class="mb-5 text-sm text-body-light-11 dark:text-body-dark-11"
                  >
                    Sócio CFO
                  </p>
                  <div className="flex items-center justify-center gap-5">
                    <a
                      href="https://www.linkedin.com/in/jorge-luís-motta-891a489/"
                      className="text-body-light-10 dark:text-body-dark-10 hover:text-[#37bdc6]"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section id="next-step-flow" className="section-area py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-10">
            Dar o próximo passo é super simples
          </h2>
          
          <div className="flow-container flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0 md:space-x-12">
            {/* Step 1 */}
            <div className="step-item relative flex flex-col items-center text-center bg-white p-8 rounded-lg shadow-lg max-w-xs">
              <div className="step-number h-12 w-12 bg-[#37bdc6] text-white rounded-full flex items-center justify-center font-bold text-xl mb-4">
                1
              </div>
              <p className="text-lg font-medium text-gray-700">
                Você preenche o formulário abaixo
              </p>
            </div>
            
            {/* Step 2 */}
            <div className="step-item relative flex flex-col items-center text-center bg-white p-8 rounded-lg shadow-lg max-w-xs">
              <div className="step-number h-12 w-12 bg-[#37bdc6] text-white rounded-full flex items-center justify-center font-bold text-xl mb-4">
                2
              </div>
              <p className="text-lg font-medium text-gray-700">
                Agendamos uma conversa e você recebe um diagnóstico da sua empresa
              </p>
            </div>
            
            {/* Step 3 */}
            <div className="step-item relative flex flex-col items-center text-center bg-white p-8 rounded-lg shadow-lg max-w-xs">
              <div className="step-number h-12 w-12 bg-[#37bdc6] text-white rounded-full flex items-center justify-center font-bold text-xl mb-4">
                3
              </div>
              <p className="text-lg font-medium text-gray-700">
                Você decide se nossa metodologia é para o seu negócio!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Contact section --> */}
      <section id="formSection" class="section-area">
        <div class="container">
          <div class="row">
            <div class="col-12 xl:col-4">
              <div class="row">
              <div className="col-12 md:col-6 xl:col-12">
                <div className="scroll-revealed py-5 px-6 rounded-xl shadow-card-1 bg-body-light-1 dark:bg-primary-dark-2 flex gap-6 hover:shadow-lg">
                  <div className="w-[50px] h-[50px] flex items-center justify-center rounded-lg bg-[#37bdc6]">
                    <FaPhone className="text-white text-[1rem]" />
                  </div>
                  <div>
                    <h4 className="text-[1.25rem] text-[#37bdc6] mb-3">Contato</h4>
                    <p className="m-0">+55 (11) 93959-8454</p>
                    <p className="m-0">contato@vbmc.com.br</p>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 xl:col-12">
                <div className="scroll-revealed py-5 px-6 rounded-xl shadow-card-1 bg-body-light-1 dark:bg-primary-dark-2 flex gap-6 hover:shadow-lg">
                  <div className="w-[50px] h-[50px] flex items-center justify-center rounded-lg bg-[#37bdc6]">
                    <FaClock className="text-white text-[1rem]" />
                  </div>
                  <div>
                    <h4 className="text-[1.25rem] text-[#37bdc6] mb-3">Horários</h4>
                    <p className="m-0">Atendimento online: 24/7</p>
                    <p className="m-0">Escritório: 8:00 - 18:00</p>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div class="col-12 xl:col-8">
              <div
                class="scroll-revealed bg-body-light-1 dark:bg-primary-dark-2 rounded-xl py-8 sm:py-12 px-6 sm:px-10 z-10 relative shadow-card-1 hover:shadow-lg"
              >
                <div class="text-center max-w-[550px] mx-auto mb-12">
                  <h6 class="mb-2 block text-lg font-semibold text-[#37bdc6]">
                    Entre em contato
                  </h6>
                  <h3 class="mb-3">Garanta uma gestão eficiente agora!</h3>
                </div>

                

                <form onSubmit={handleSubmit} class="w-full mx-auto p-6 bg-white rounded-lg shadow-lg">
                  <div class="grid grid-cols-1 gap-6">
                    <div>
                      <label class="block text-md font-regular text-gray-700 mb-2">
                        Qual o seu nome?
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Nome"
                        required
                        class="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      />
                    </div>

                    <div>
                      <label class="block text-md font-regular text-gray-700 mb-2">
                        Qual o seu email?
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        class="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      />
                    </div>

                    <div>
                      <label class="block text-md font-regular text-gray-700 mb-2">
                        Qual o seu número?
                      </label>
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Número"
                        required
                        class="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      />
                    </div>

                    <div>
                      <label for="faturamento" class="block text-md font-regular text-gray-700 mb-2">
                        Qual o faturamento anual da sua empresa?
                      </label>
                      <select
                        id="faturamento"
                        name="faturamento"
                        required
                        class="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      >
                        <option value="" disabled selected>Selecione o faturamento</option>
                        <option value="Até R$ 4,8 milhões /ano">Até R$ 4,8 milhões /ano</option>
                        <option value="De R$ 4,8 milhões até R$ 10 milhões /ano">De R$ 4,8 milhões até R$ 10 milhões /ano</option>
                        <option value="De R$ 10 milhões até R$ 15 milhões /ano">De R$ 10 milhões até R$ 15 milhões /ano</option>
                        <option value="De R$ 15 milhões até R$ 18 milhões /ano">De R$ 15 milhões até R$ 18 milhões /ano</option>
                        <option value="De R$ 18 milhões até R$ 50 milhões /ano">De R$ 18 milhões até R$ 50 milhões /ano</option>
                        <option value="Acima de R$ 50 milhões /ano">Acima de R$ 50 milhões /ano</option>
                      </select>
                    </div>

                    <div>
                      <label for="desafio" class="block text-md font-regular text-gray-700 mb-2">
                        Qual é o principal desafio que sua empresa enfrenta atualmente?
                      </label>
                      <select
                        id="desafio"
                        name="desafio"
                        required
                        class="w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      >
                        <option value="" disabled selected>Selecione um desafio</option>
                        <option value="Aumento de faturamento">Aumento de faturamento</option>
                        <option value="Melhoria nos processos">Melhoria nos processos</option>
                        <option value="Melhoria no nível de gestão">Melhoria no nível de gestão</option>
                        <option value="Capacitação de pessoas">Capacitação de pessoas</option>
                        <option value="Expansão para novos mercados">Expansão para novos mercados</option>
                        <option value="Implementação de novas tecnologia de gestão">Implementação de novas tecnologia de gestão</option>
                        <option value="Redução de custos e despesas">Redução de custos e despesas</option>
                        <option value="Outro">Outro</option>
                      </select>

                      <textarea
                        name="desafio-outro"
                        placeholder="Por favor, especifique..."
                        class="mt-4 w-full px-5 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary hidden"
                      ></textarea>
                    </div>
                  </div>

                  <div class="mt-6 text-center">
                    <button
                      type="submit"
                      class="px-8 py-3 bg-primary text-white rounded-md hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-primary"
                    >
                      Receber um diagnóstico
                    </button>
                  </div>
                </form>




              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Map section --> */}
      <section id="map" class="w-full h-[500px] overflow-hidden -mt-[12rem]">
        <object
          data="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14639.60496231772!2d-46.8710518!3d-23.4640266!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf1b48bf3088c3%3A0x125da050ef40407b!2sVBMC%20Consultores!5e0!3m2!1spt-BR!2ssv!4v1730266512962!5m2!1spt-BR!2ssv"
          class="border-0 w-full h-full"
          aria-label="Embedded map showing the location of VBMC Consultores"
        ></object>
      </section>

      {/* Por que escolher a Consultoria da VBMC? */}
      <section id="why-choose-vbmc" className="section-area py-16 bg-gradient-to-b from-[#e6eef6] to-[#f9fbfd]">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">
            Por que escolher a Consultoria da VBMC?
          </h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-14">
            Nossa expertise proporciona resultados reais e sustentáveis em performance operacional. Conheça o que nos torna a escolha ideal para o sucesso do seu negócio:
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            
            {/* Card Item - Experiência */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaUsers className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Experiência Consolidada</h3>
              <p className="text-gray-600">
                Mais de 100 empresas atendidas com estratégias eficazes de gestão.
              </p>
            </div>
            
            {/* Card Item - Consultoria Integrada */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaHandshake className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Consultoria Integrada</h3>
              <p className="text-gray-600">
                Trabalhamos lado a lado com seu time para resultados sólidos e mensuráveis.
              </p>
            </div>
            
            {/* Card Item - Reputação */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaMedal className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">4 Décadas de Excelência</h3>
              <p className="text-gray-600">
                Uma reputação sólida construída em 40 anos de sucesso.
              </p>
            </div>
            
            {/* Card Item - Resultados Mensuráveis */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaChartLine className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Resultados Mensuráveis</h3>
              <p className="text-gray-600">
                Resultados comprovados em cases de clientes satisfeitos.
              </p>
            </div>
            
            {/* Card Item - Metodologia Prática */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaCogs className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Metodologia Prática</h3>
              <p className="text-gray-600">
                Adaptada às necessidades específicas do seu contexto.
              </p>
            </div>
            
            {/* Card Item - Suporte Diário */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaLifeRing className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Suporte Diário</h3>
              <p className="text-gray-600">
                Acompanhamento dedicado para garantir o sucesso do projeto.
              </p>
            </div>
            
            {/* Card Item - Segurança e Privacidade */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaShieldAlt className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Segurança e Privacidade</h3>
              <p className="text-gray-600">
                Compromisso total com a segurança das suas informações.
              </p>
            </div>
            
            {/* Card Item - Ferramentas Inovadoras */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaLightbulb className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Ferramentas Inovadoras</h3>
              <p className="text-gray-600">
                Acesso às ferramentas de gestão mais avançadas do mercado.
              </p>
            </div>
            
            {/* Card Item - Atendimento Dedicado */}
            <div className="flex flex-col items-center p-6 bg-white shadow-lg rounded-2xl transition-transform hover:-translate-y-2 hover:shadow-2xl">
              <div className="icon bg-gradient-to-r from-[#3b82f6] to-[#10b981] p-4 rounded-full mb-6">
                <FaTools className="text-white text-3xl" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Atendimento ao Cliente</h3>
              <p className="text-gray-600">
                Uma equipe sempre ao seu lado em cada etapa do projeto.
              </p>
            </div>

          </div>
        </div>
      </section>
    </main>

    <button
      type="button"
      class="inline-flex w-12 h-12 rounded-md items-center justify-center text-lg/none bg-primary text-[#37bdc6]-color hover:bg-primary-light-10 dark:hover:bg-primary-dark-10 focus:bg-primary-light-10 dark:focus:bg-primary-dark-10 fixed bottom-[117px] right-[20px] hover:-translate-y-1 opacity-100 visible z-50 is-hided"
      data-web-trigger="scroll-top"
      aria-label="Scroll to top"
    >
      <i class="lni lni-chevron-up"></i>
    </button>

    <script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"></script>
    <script src="https://cdn.jsdelivr.net/gh/mcstudios/glightbox/dist/js/glightbox.min.js"></script>
    <script src="https://unpkg.com/scrollreveal@4.0.0/dist/scrollreveal.min.js"></script>

    <script src="./assets/js/main.js"></script>
    
  </body>
  );
};

export default LPPage3;
