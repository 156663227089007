import React from 'react';

const WhatsAppButton = () => {
  const phoneNumber = "5511939598454";
  const message = encodeURIComponent("Olá! Gostaria de otimizar a gestão do meu negócio!");
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-5 right-5 bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg flex items-center justify-center transition duration-300"
      style={{ width: "60px", height: "60px", zIndex: 1000 }}
    >
      {/* Ícone do WhatsApp */}
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        className="w-8 h-8"
      />
    </a>
  );
};

export default WhatsAppButton;
